<template>
  <div class="redemption-page">
    <!-- NAVBAR START -->
    <nav id="nav-menu" class="navbar navbar-expand-lg py-lg-3 mb-2 sticky-top">
      <div class="container">
        <!-- logo -->
        <a
          v-if="config"
          href="/"
          class="navbar-brand mr-lg-5 font-size-22 font-weight-bold text-dark"
        >
          <img :src="config.logo" alt="" class="logo-dark mr-2" height="24" />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="uil uil-bars"></i>
        </button>

        <div class="collapse navbar-collapse">
          <ul
            class="navbar-nav ml-auto align-items-center font-size-16 font-weight-bold"
          >
            <li class="nav-item mr-0 ml-4">
              <router-link
                v-if="user"
                class="btn btn-primary d-none d-lg-inline-flex"
                :to="'/' + user.role.toLocaleLowerCase()"
              >
                <i data-feather="shopping-bag" class="icon-xs mr-2 mt-1"></i>
                Dashboard
              </router-link>

              <router-link
                v-else
                class="btn btn-primary d-none d-lg-inline-flex"
                :to="{ name: 'login' }"
              >
                <i data-feather="shopping-bag" class="icon-xs mr-2 mt-1"></i>
                Login
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="redemption-container">
      <div class="container">
        <div v-if="step === 1" class="step-1">
          <h3 class="title text-primary">{{ businessName }}</h3>
          <div class="form-wrapper">
            <b-tabs>
              <b-tab
                title="Dynamic"
                active
                @click="resetData"
              >
                <div class="card">
                  <div class="card-body">
                    <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="redemptionForm"
                    >
                      <form @submit.prevent="handleSubmit(onRedemption)">
                        <div v-if="campaign" class="text-center mb-4 font-size-18 text-primary">
                          <b v-if="campaign.service_name"><i>{{ campaign.service_name }}</i></b>
                          <b v-else><i>{{ campaign.name }} - {{ campaign.discount_type }}</i></b>
                          <span v-if="redemption.status === 'expired'" class="d-block text-danger font-size-14">(Redemption Code Expired On {{redemption.expired_at | moment($store.getters['auth/dateFormat'])}})</span>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6">
                            <PhoneInput
                              v-model="coupon.phone"
                              name="phone"
                              label="Phone Number"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.redemption_code"
                              name="redemption_code"
                              rules="required"
                              label="Redemption Code"
                              type="number"
                              sublabel="(*)"
                              @changed="handleCodeChange"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.amount"
                              name="amount"
                              rules="required"
                              label="Total Amount Spent"
                              type="number"
                              sublabel="(*)"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.first_name"
                              name="first_name"
                              label="First Name"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.last_name"
                              name="last_name"
                              label="Last Name"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.email"
                              name="email"
                              rules="email"
                              label="Email"
                            />
                          </div>
                          <div class="col-md-6">
                            <BirthdayInput v-model="coupon.birthday" />
                          </div>
                          <!-- <div class="col-md-6">
                            <BirthdayInput v-model="coupon.anniversary" label="Anniversary"/>
                          </div> -->
                          <div class="col-md-12 text-center mt-2">
                            <span class="text-danger">*required</span>
                          </div>
                        </div>
                        <b-form-group class="text-center">
                          <b-button
                            variant="primary"
                            :disabled="loadingSend || (invalid && touched)"
                            type="submit"
                            class="w-50 mt-3"
                          >
                            <b-spinner v-if="loadingSend" small />
                            <span v-else>Submit</span>
                          </b-button>
                        </b-form-group>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </b-tab>
              <b-tab
                title="Static"
                @click="resetData"
              >
                <div class="card">
                  <div class="card-body">
                    <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="redemptionStaticForm"
                    >
                      <form @submit.prevent="handleSubmit(onRedemption)">
                        <div v-if="campaign" class="text-center mb-4 font-size-18 text-primary">
                          <b v-if="campaign.service_name"><i>{{ campaign.service_name }}</i></b>
                          <b v-else><i>{{ campaign.name }} - {{ campaign.discount_type }}</i></b>
                          <span v-if="redemption.status === 'expired'" class="d-block text-danger font-size-14">(Redemption Code Expired On {{redemption.expired_at | moment($store.getters['auth/dateFormat'])}})</span>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6">
                            <PhoneInput
                              v-model="coupon.phone"
                              name="phone"
                              label="Phone Number"
                              @input="handleStaticCodeChange"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.coupon_code"
                              name="coupon_code"
                              rules="required"
                              label="Coupon Code"
                              sublabel="(*)"
                              @changed="handleStaticCodeChange"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.amount"
                              name="amount"
                              rules="required"
                              label="Total Amount Spent"
                              type="number"
                              sublabel="(*)"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.first_name"
                              name="first_name"
                              label="First Name"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.last_name"
                              name="last_name"
                              label="Last Name"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                              v-model="coupon.email"
                              name="email"
                              rules="email"
                              label="Email"
                              @changed="handleStaticCodeChange"
                            />
                          </div>
                          <div class="col-md-6">
                            <BirthdayInput v-model="coupon.birthday" />
                          </div>
                          <!-- <div class="col-md-6">
                            <BirthdayInput v-model="coupon.anniversary" label="Anniversary"/>
                          </div> -->
                          <div class="col-md-12 text-center mt-2">
                            <span class="text-danger">*required</span>
                          </div>
                        </div>
                        <b-form-group class="text-center">
                          <b-button
                            variant="primary"
                            :disabled="loadingSend || (invalid && touched)"
                            type="submit"
                            class="w-50 mt-3"
                          >
                            <b-spinner v-if="loadingSend" small />
                            <span v-else>Submit</span>
                          </b-button>
                        </b-form-group>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <div v-if="step === 2" class="step-2">
          <h3 class="success-text">
            Redemption has been successfully done. Thanks for your using our
            service.
          </h3>

          <button class="btn btn-outline-dark" @click="goBack"
            >Go back</button
          >
        </div>
      </div>
    </div>
    <b-modal v-model="showConfirmModal" title="Confirmation?">
      <div>
        <span>This code has expired. Are you sure you want to submit it?</span>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="saveData"
          >Submit</b-button
        >
        <b-button variant="danger" @click="showConfirmModal = false"
          >Close</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  data() {
    return {
      showConfirmModal: false,
      loadingSend: false,
      coupon: {},
      couponDefault: {
        first_name: '',
        last_name: '',
        email: '',
        birthday: null,
        amount: '',
        redemption_code: '',
        coupon_code: '',
        phone: '',
        unique_id: '',
        business_id: null,
      },
      redemption: {},
      step: 1,
      campaign: null,
      business: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    businessName () {
      return this.business && this.business.name
    },

    config() {
      return this.$store.getters['app/config']
    },
  },

  mounted() {
    this.coupon.unique_id = this.$route.params.unique_id
    this.couponDefault.unique_id = this.$route.params.unique_id

    this.getBusiness()
  },

  methods: {
    handleStaticCodeChange() {
      if (this.redemption)
        return
      let phone = this.coupon.phone
      if (phone) {
        phone = parsePhoneNumberFromString(this.coupon.phone)
        phone = phone ? phone.number: null
      }
      let email = this.coupon.email
      if (this.coupon.coupon_code && (phone || email))
      {
        const param = {
          code: this.coupon.coupon_code,
          phone,
          email,
          uniqueId: this.$route.params.unique_id
        }
        this.$store.dispatch('coupon/findByStaticCode', param)
          .then(campaign => {
            this.campaign = campaign
            this.coupon = { ...this.coupon, ...campaign.contact }
            this.redemption = campaign.redemption
          })
          .catch(() => {
            let errMsg = 'Redemption code is invalid'
            let errors = {
              coupon_code: errMsg
            }
            this.$refs.redemptionStaticForm.setErrors(errors)
            this.resetForm()
          })
      }
    },

    onRedemption() {
      if (this.redemption && this.redemption.status === 'expired')
      {
        this.showConfirmModal = true;
      } else {
        this.saveData()
      }
    },

    saveData() {
      this.showConfirmModal = false;
      this.loadingSend = true
      const requestParam = Object.assign({}, this.coupon)

      this.$store
        .dispatch('coupon/redemption', requestParam)
        .then((res) => {
          this.loadingSend = false
          this.step = 2
          this.resetData()
        })
        .catch((err) => {
          let errors = err.response.data.errors
          if (errors && errors['redemption_code']) {
            errors.redemption_code.reverse()
          }

          if (errors) {
            this.$refs.redemptionForm.setErrors(errors)
          }
          this.loadingSend = false
        })
    },

    resetData() {
      this.coupon = Object.assign({}, this.couponDefault)
      this.resetForm()
    },

    goBack() {
      this.campaign = null
      this.step = 1
    },

    handleCodeChange(code) {
      if (!code) {
        this.resetForm()
        return;
      }

      const param = {
        code,
        uniqueId: this.$route.params.unique_id
      }

      this.$store.dispatch('coupon/findByCode', param)
        .then(campaign => {
          this.campaign = campaign
          this.coupon = { ...this.coupon, ...campaign.contact }
          this.redemption = campaign.redemption
        })
        .catch((err) => {
          let errMsg = 'Redemption code is invalid'
          if (err.response.data.used)
            errMsg = 'Redemption code has been already redeemed'
          let errors = {
            redemption_code: errMsg
          }
          this.$refs.redemptionForm.setErrors(errors)
          this.resetForm()
        })
    },

    resetForm() {
      this.campaign = null
      this.redemption = null
    },

    getBusiness() {
      this.$store
          .dispatch('business/findByUniqueId', this.$route.params.unique_id)
          .then((business) => {
            this.business = business
            this.coupon.business_id = business.id
            this.couponDefault.business_id = business.id
            if (business.unique_id !== this.$route.params.unique_id) {
              location.pathname = location.pathname.replace(/\/.*\//, `/${business.unique_id}/`)
            }
          })
          .catch(() => {
          })
    },
  },
}
</script>

<style lang="scss">
.redemption-page {
  .redemption-container {
    margin-top: 30px;
    .form-wrapper {
      max-width: 680px;
      width: 100%;
      margin: auto;
      margin-top: 30px;
    }
    .title {
      text-align: center;
    }
    .step-2 {
      margin-top: 150px;
      text-align: center;
      .success-text {
        color: #15bd15;
        margin-bottom: 30px;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    .form-control.flatpickr-input {
      background: #fff !important;
    }
  }
}
</style>
